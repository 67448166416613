//type
import { ActivityConfig } from '@components/dashboard/Activity/interfaces/DashboardActivities';

//library
import React, { ReactElement } from 'react';

//code
import Toolbar from '@components/dashboard/Activity/app/view/form/Toolbar';
import ActivityTable from '@components/dashboard/Activity/app/view/layout/ActivityTables';

/**
 * Main page for activity dashboard
 *
 * @param {ActivityConfig} config - The main props
 * @returns {ReactElement} - The activity dashboard page
 */
const Main: React.FC<ActivityConfig> = (config: ActivityConfig): ReactElement => {
    const {
        module: {
            layout: { Menu }
        }
    } = config.lib;

    return (
        <div className="flex" aria-label="sales-dashboard">
            <Menu data={{ current: 'DASHBOARD', currentSubMenu: 'ACTIVITIES' }} lib={config.lib} />

            <div className="block w-full h-full">
                <div className="sticky top-0 left-0">
                    <Toolbar {...config} />
                </div>

                <div className="mt-5 w-full">
                    <ActivityTable {...config} />
                </div>
            </div>
        </div>
    );
};

export default Main;
