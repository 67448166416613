///types
import {
    GetDashboardActivitiesAndDurationsQuery,
    GetDashboardActivitiesQueryVariables
} from '@interfaces/graphql/API';
import { AmplifyAPI } from '@components/lib/interfaces';

//library
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { getDashboardActivitiesAndDurations } from '@components/graphql/queries';

/**
 * Get the list of activity stats.
 *
 * @param {AmplifyAPI} API - The API to make graphql call.
 * @param {GetDashboardActivitiesQueryVariables} query - The query.
 * @returns {GetDashboardActivitiesAndDurationsQuery} - The list of activity and sales.
 */
const getActivitiesAndDurationsQuery = async (
    API: AmplifyAPI,
    query: GetDashboardActivitiesQueryVariables
): Promise<GetDashboardActivitiesAndDurationsQuery> => {
    const response: GraphQLResult<GetDashboardActivitiesAndDurationsQuery> = (await API.graphql({
        query: getDashboardActivitiesAndDurations,
        variables: { ...query }
    })) as GraphQLResult<GetDashboardActivitiesAndDurationsQuery>;

    return {
        getDashboardActivities: response.data?.getDashboardActivities || {
            __typename: 'ListActivities',
            activity: []
        },
        getDashboardActivityDurations: response.data?.getDashboardActivityDurations || {
            __typename: 'ListActivityDurations',
            duration: []
        }
    };
};

export default getActivitiesAndDurationsQuery;
