//types
import { ActivityDuration } from '@interfaces/graphql/API';
import { GroupedDuration } from '@components/dashboard/Activity/interfaces/app/model/resolver/groupDurationRsl';

const formatDuration = (duration: number) => {
    // Convert duration to seconds
    let seconds = Math.floor(duration / 1000);

    // Calculate number of days and hours (1 d.p)
    const days = Math.floor(seconds / 86400);
    seconds -= days * 86400;

    const hours = Math.floor(seconds / 3600);

    // Create formatted string
    let result = '';
    if (days > 0) {
        result += days + 'd ';
    }
    if (hours > 0) {
        result += hours + 'h ';
    }

    return result;
};

/**
 * Mapper function to format the duration stats
 *
 * @param {GroupedDuration} result - The formatted duration values
 * @param {ActivityDuration} duration - The duration stats
 * @returns {GroupedDuration} - The formatted duration values
 */
const mapDuration = (result: GroupedDuration, duration: ActivityDuration): GroupedDuration => {
    const key = `${duration.status}${duration.owner}`;

    result[key] = {
        avg: formatDuration(duration.avg),
        median: formatDuration(duration.median),
        min: formatDuration(duration.min),
        max: formatDuration(duration.max),
        owner: duration.owner,
        status: duration.status
    };

    return result;
};

/**
 * Format the duration stats
 *
 * @param {ActivityDuration[]} duration - The duration stats array
 * @returns {GroupedDuration} - The formatted duration values
 */
const groupDurationRsl = (duration: ActivityDuration[]): GroupedDuration => {
    const groupedDuration = duration.reduce(mapDuration, {});

    return groupedDuration;
};

export default groupDurationRsl;
