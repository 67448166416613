//types
import { ActivityConfig } from '@components/dashboard/Activity/interfaces/DashboardActivities';

//library
import React, { ReactElement } from 'react';

/**
 * Toolbar for the activity dashhboard page.
 *
 * @param {ActivityConfig} config - Props for queries
 * @returns {ReactElement} - The toolbar
 */
const Toolbar: React.FC<ActivityConfig> = (config: ActivityConfig): ReactElement => {
    const {
        module: {
            input: { DateInput, FilterInput }
        },
        external: {
            Formik: { Formik, Form },
            Yup
        }
    } = config.lib;

    const { initialValues, submit, listProduct } = config.controller.useToolbarCtrl();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                from: Yup.string().required(),
                to: Yup.string().required(),
                product: Yup.array()
            })}
            enableReinitialize
            onSubmit={submit}>
            <Form
                aria-label="toolbar-form"
                className="w-full bg-white flex flex-row items-center text-xs px-6 py-2">
                <FilterInput
                    {...{
                        ...config,
                        data: {
                            options: listProduct,
                            fieldName: 'product',
                            tooltip: false
                        }
                    }}
                />
                <div className="hidden sm:block w-1 h-8 border-l-2 border-gray-200 ml-4 mr-7 my-3" />
                <div className="flex flex-row items-center gap-2">
                    <span className="text-sm font-bold text-navy">From:</span>
                    <div className="w-28 relative">
                        <DateInput
                            {...{
                                ...config,
                                data: {
                                    field: 'from',
                                    isCompact: true,
                                    isClearable: false
                                }
                            }}
                        />
                    </div>
                    <span className="text-sm font-bold text-navy">To:</span>
                    <div className="w-28 relative">
                        <DateInput
                            {...{
                                ...config,
                                data: {
                                    field: 'to',
                                    isCompact: true,
                                    isClearable: false
                                }
                            }}
                        />
                    </div>
                </div>
                <button
                    type="submit"
                    aria-label="submit-button"
                    className="w-7 h-7 rounded-full bg-elements flex items-center justify-center ml-4">
                    <svg
                        height="16"
                        width="16"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="#FFF">
                        <path d="M392.052,0l-0.642,0.01c0,0,0,0,0.009,0c0,0,0-0.01,0.008,0L392.052,0z" />
                        <path
                            d="M487.66,61.494l-0.037-0.037c-0.056-0.056-0.102-0.111-0.156-0.166h-0.01
		c-0.064-0.056-0.092-0.092-0.193-0.183l-36.51-36.511c-0.009-0.018-0.028-0.027-0.046-0.046
		C434.386,8.23,412.811-0.027,391.419,0.01c-21.392-0.037-42.968,8.211-59.308,24.56L297.75,58.931l-13.043,13.033L0,356.681V512
		h155.327l284.708-284.698l13.042-13.042l34.362-34.37c16.34-16.332,24.588-37.916,24.56-59.299
		c0.027-21.291-8.156-42.766-24.313-59.06L487.66,61.494z M136.572,466.736h-91.3v-91.299l271.445-271.455l91.299,91.3
		L136.572,466.736z M455.429,147.878l-21.327,21.318l-91.29-91.299L364.13,56.58c7.578-7.569,17.35-11.279,27.289-11.306
		c9.938,0.027,19.702,3.738,27.288,11.306l36.722,36.712c7.569,7.587,11.279,17.36,11.298,27.298
		C466.708,130.528,462.998,140.292,455.429,147.878z"
                        />
                    </svg>
                </button>
            </Form>
        </Formik>
    );
};

export default Toolbar;
