//type
import { ActivityConfig } from '@components/dashboard/Activity/interfaces/DashboardActivities';

//library
import React, { ReactElement } from 'react';

//code
import ActivityStatisticsRows from '@components/dashboard/Activity/app/view/layout/ActivityStatisticsRows';

/**
 * Activity table for activity dashboard page
 *
 * @param {ActivityConfig} config - The main props
 * @returns {ReactElement} - The activity dashboard page
 */
const ActivityTable: React.FC<ActivityConfig> = (config: ActivityConfig): ReactElement => {
    const {
        template: {
            layout: { Loader, DashboardRows }
        },
        external: { query }
    } = config.lib;

    const { state, activityStatusTitles, durationStatusTitles, summaryStatistics } =
        config.controller.useMainCtrl();
    const { activity, duration, sales, loading, error } = state;

    return (
        <div className="lg:w-11/12 xl:w-4/5 mx-auto mb-20">
            <div className="text-navy text-lg font-bold mb-4">
                Prospect activity from {query.from} to {query.to}:
            </div>

            {!loading && (
                <>
                    <div
                        aria-label="activity-table"
                        className={`text-left text-sm text-gray-500 w-full bg-primary grid grid-cols-autofit border-y-2 border-athens-gray py-4 mb-2`}>
                        <div className="col-span-2">Count</div>

                        {sales.map((sale, index) => (
                            <div key={index}>{sale.label}</div>
                        ))}
                    </div>
                    <DashboardRows
                        {...{
                            ...config,
                            data: {
                                statuses: activityStatusTitles,
                                sales,
                                stats: activity
                            }
                        }}
                    />

                    <div
                        className={`text-left text-sm text-gray-500 w-full bg-primary grid grid-cols-autofit border-y-2 border-athens-gray py-4 mt-14 mb-2`}>
                        <div className="col-span-2">Time in Stage</div>

                        {sales.map((sale, index) => (
                            <div key={index}>{sale.label}</div>
                        ))}
                    </div>
                    {durationStatusTitles.map((status) => (
                        <div key={status}>
                            <div className="text-navy text-sm font-bold">
                                {status.replace(/_/g, ' ')}
                            </div>
                            <div className="mb-5">
                                <ActivityStatisticsRows
                                    {...{
                                        ...config,
                                        data: {
                                            sales,
                                            stats: duration,
                                            statistics: summaryStatistics,
                                            status
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </>
            )}

            {loading && (
                <div className="w-full h-5 m-6" aria-label="loading">
                    <Loader />
                </div>
            )}

            {error && (
                <div className="w-full text-center mt-6 text-red-500" aria-label="error">
                    {error}
                </div>
            )}
        </div>
    );
};

export default ActivityTable;
