//types
import { Activity } from '@interfaces/graphql/API';
import { GroupedActivity } from '@components/dashboard/Activity/interfaces/app/model/resolver/groupActivityRsl';

/**
 * Mapper function to format the activity stats
 *
 * @param {GroupedActivity} result - The formatted activity values
 * @param {Activity} activity - The activity stats
 * @returns {GroupedActivity} - The formatted activity values
 */
const mapActivity = (result: GroupedActivity, activity: Activity): GroupedActivity => {
    const key = `${activity.status}${activity.owner}`;

    result[key] = {
        count: activity.count,
        owner: activity.owner,
        status: activity.status
    };

    return result;
};

/**
 * Format the activity stats
 *
 * @param {Activity[]} activity - The activity stats array
 * @returns {GroupedActivity} - The formatted activity values
 */
const groupActivityRsl = (activity: Activity[]): GroupedActivity => {
    const groupedActivity = activity.reduce(mapActivity, {});

    return groupedActivity;
};

export default groupActivityRsl;
