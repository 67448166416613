//types
import { ActivityConfig } from '@components/dashboard/Activity/interfaces/DashboardActivities';

//library
import React, { ReactElement } from 'react';

/**
 * The rows for activity table
 *
 * @param {ActivityConfig} config - The data for activity table
 * @returns {ReactElement} - The rows for activity table
 */
const ActivitySummary: React.FC<ActivityConfig> = (config: ActivityConfig): ReactElement => {
    const { summaryValue } = config.data;

    const {
        external: {
            query: { product }
        },
        list: { residentialProduct }
    } = config.lib;

    return (
        <div
            aria-label="activity-summary"
            className={`px-2 py-1 ${
                typeof product === 'string' && `${residentialProduct[product].selectedColor}`
            }`}>
            {summaryValue}
        </div>
    );
};

export default ActivitySummary;
