//Library
import React from 'react';

//type
import { DashboardRowsProps } from '@components/lib/interfaces/template/layout/DashboardRows';

/**
 * The dashboard table rows.
 *
 * @param {DashboardRowsProps} props - The props.
 * @returns {React.ReactElement} - The loader.
 */
const DashboardRows: React.FC<DashboardRowsProps> = (
    props: DashboardRowsProps
): React.ReactElement => {
    const {
        data: { sales, statuses, stats },
        lib: {
            external: {
                query: { product }
            },
            list: { residentialProduct }
        }
    } = props;

    return (
        <>
            {statuses.map((status, index) => (
                <div
                    key={`${index}${status}`}
                    className={`grid grid-cols-autofit items-center text-sm text-navy font-bold`}>
                    <div className="col-span-2">{status.replace(/_/g, ' ')}</div>

                    {sales.map((sale, index) => (
                        <div
                            key={`${status}${sale.label}${index}`}
                            className="h-7 flex items-center justify-start text-navy font-bold text-sm">
                            <div
                                aria-label={`${status}${sale.label}`}
                                className={`px-2 py-1 ${
                                    typeof product === 'string' &&
                                    `${residentialProduct[product].selectedColor}`
                                }`}>
                                {stats[`${status}${sale.value}`]?.count || 0}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

export default DashboardRows;
