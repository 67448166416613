//types
import { ActivityProps } from '@components/dashboard/Activity/interfaces/DashboardActivities';
import {
    ToolbarFormValues,
    GetActivitiesAndDurationsProps,
    GetActivitiesAndDurationsReturn,
    Statistics,
    ActivityModelReturn
} from '@components/dashboard/Activity/interfaces/app/model/activityModel';

//library
import { ParsedUrlQuery } from 'querystring';
import { API } from 'aws-amplify';

//code
import getActivitiesAndDurationsQuery from '@components/dashboard/Activity/app/model/query/getActivitiesAndDurationsQuery';

//resolver
import getToolbarValuesRsl from '@components/dashboard/Activity/app/model/resolver/getToolbarValuesRsl';
import groupActivityRsl from '@components/dashboard/Activity/app/model/resolver/groupActivityRsl';
import groupDurationRsl from '@components/dashboard/Activity/app/model/resolver/groupDurationRsl';

//api
import {
    GetDashboardActivitiesQueryVariables,
    PRODUCT,
    PROSPECT_STATUS
} from '@interfaces/graphql/API';

/**
 * Activity Model
 *
 * @param {ActivityProps} props - The props
 * @returns {ActivityModelReturn} - The model function.
 */
const activityModel = ({
    lib: {
        model: {
            resolver: { updateUrlRsl, formatCountryCodeRsl }
        },
        external: { query, router }
    }
}: ActivityProps): ActivityModelReturn => {
    const toolbarInitialValues: ToolbarFormValues = getToolbarValuesRsl(query);

    const activityStatusTitles: PROSPECT_STATUS[] = [
        PROSPECT_STATUS.CONNECTED,
        PROSPECT_STATUS.PROPOSAL_SENT,
        PROSPECT_STATUS.CONNECTED_TO_EPC,
        PROSPECT_STATUS.SITE_VISIT_COMPLETE,
        PROSPECT_STATUS.CLOSED_WON,
        PROSPECT_STATUS.CLOSED_LOST,
        PROSPECT_STATUS.REVISIT
    ];

    const durationStatusTitles: PROSPECT_STATUS[] = [
        PROSPECT_STATUS.CONNECTED,
        PROSPECT_STATUS.PROPOSAL_SENT,
        PROSPECT_STATUS.CONNECTED_TO_EPC,
        PROSPECT_STATUS.SITE_VISIT_COMPLETE,
        PROSPECT_STATUS.REVISIT
    ];

    const summaryStatistics: Statistics[] = ['avg', 'median', 'min', 'max'];

    const countryCode = formatCountryCodeRsl(router.locale);

    /**
     * Get the list of activities data
     *
     * @param {GetActivitiesAndDurationsProps} props - The props
     * @returns {GetActivitiesAndDurationsReturn} - The data returned from getDashboardActivities endpoint.
     */
    const getActivitiesAndDurations = async ({
        query
    }: GetActivitiesAndDurationsProps): GetActivitiesAndDurationsReturn => {
        query = getToolbarValuesRsl(
            query as ParsedUrlQuery
        ) as GetDashboardActivitiesQueryVariables;

        const data = await getActivitiesAndDurationsQuery(API, {
            ...query,
            country_code: countryCode
        });
        const activity = groupActivityRsl(data.getDashboardActivities.activity);
        const duration = groupDurationRsl(data.getDashboardActivityDurations.duration);

        return { activity, duration };
    };

    /**
     * Update the query and set the url
     *
     * @param {GetDashboardActivitiesQueryVariables} query - The query
     */
    const setUrl = (query?: GetDashboardActivitiesQueryVariables) => {
        const today = new Date();
        const to = today.toISOString().split('T')[0];

        today.setDate(today.getDate() - 30);
        const from = today.toISOString().split('T')[0];

        const newQuery = updateUrlRsl(
            {
                product: [PRODUCT.QC, PRODUCT.RTO, PRODUCT.DP],
                from,
                to
            },
            query
        );

        router.push({ pathname: router.pathname, query: newQuery });
    };

    /**
     * Submit the toolbar form
     *
     * @param {ToolbarFormValues} values - The toolbar form values
     */
    const submitToolbarForm = (values: ToolbarFormValues) => {
        const from = new Date(values.from).getTime();
        const to = new Date(values.to).getTime();

        if (from > to) {
            setUrl({ ...values, country_code: countryCode, from: values.to, to: values.from });

            return;
        }

        setUrl({ ...values, country_code: countryCode });
    };

    return {
        toolbarInitialValues,
        activityStatusTitles,
        durationStatusTitles,
        summaryStatistics,
        getActivitiesAndDurations,
        setUrl,
        submitToolbarForm
    };
};

export default activityModel;
