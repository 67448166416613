//types
import { ActivityConfig } from '@components/dashboard/Activity/interfaces/DashboardActivities';

//library
import React, { ReactElement } from 'react';

//code
import ActivitySummary from '@components/dashboard/Activity/app/view/layout/ActivitySummary';

/**
 * The rows for activity statistics table
 *
 * @param {ActivityConfig} config - The data for activity table
 * @returns {ReactElement} - The rows for activity table
 */
const ActivityStatisticsRows: React.FC<ActivityConfig> = (config: ActivityConfig): ReactElement => {
    const { sales, status, stats, statistics } = config.data;

    return (
        <>
            {statistics.map((statistic) => (
                <div key={statistic} className={`grid grid-cols-autofit items-center`}>
                    <div className="col-span-2 text-gray-500 text-sm ml-6">{`${statistic
                        .charAt(0)
                        .toUpperCase()}${statistic.slice(1)}`}</div>

                    {sales.map((sale) => (
                        <div
                            key={`${status}${sale.value}${statistic}`}
                            className="flex items-center justify-start text-navy font-bold text-sm">
                            <ActivitySummary
                                {...{
                                    ...config,
                                    data: {
                                        sales: [],
                                        stats: {},
                                        statistics: [],
                                        summaryValue:
                                            stats[`${status}${sale.value}`]?.[statistic] || '0d'
                                    }
                                }}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

export default ActivityStatisticsRows;
