//types
import {
    State,
    Action
} from '@components/dashboard/Activity/interfaces/app/controllers/useControllers';

export const initialState: State = {
    activity: {},
    duration: {},
    sales: [],
    loading: false,
    error: ''
};

/**
 * The reducer function to control state
 *
 * @param {State} state - The state.
 * @param {Action} action - The action and data.
 * @returns {State} - The updated state.
 */
export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'FETCH':
            state = {
                ...state,
                loading: true,
                error: ''
            };
            break;
        case 'ERROR':
            state = {
                ...state,
                loading: false,
                error: 'Something went wrong!'
            };
            break;
        case 'LOAD_ITEMS':
            state = {
                ...state,
                loading: false,
                error: '',
                activity: (action.data?.activity && action.data.activity) || state.activity,
                duration: (action.data?.duration && action.data.duration) || state.duration,
                sales: (action.data?.sales && action.data.sales) || state.sales
            };
            break;
        default:
            state = {
                ...state
            };
    }

    return state;
};
