//type
import {
    ActivityConfig,
    ActivityProps
} from '@components/dashboard/Activity/interfaces/DashboardActivities';

//library
import React, { ReactElement } from 'react';

//code
import Main from '@components/dashboard/Activity/app/view/Main';

//model
import activityModel from '@components/dashboard/Activity/app/model/activityModel';

//controller
import useControllers from '@components/dashboard/Activity/app/controllers/useControllers';

/**
 * Index page of dashboardActivities folder.
 *
 * @param {ActivityProps} props - The props
 * @returns {ReactElement} - The activity dashboard page
 */
const DashboardActivities: React.FC<ActivityProps> = (props: ActivityProps): ReactElement => {
    const config: ActivityConfig = {
        ...props,
        controller: useControllers({
            ...props,
            model: { activityModel: activityModel(props) }
        }),
        data: {
            sales: [],
            stats: {},
            statistics: []
        }
    };

    return <Main {...config} />;
};

export default DashboardActivities;
