//type
import { ToolbarFormValues } from '@components/dashboard/Activity/interfaces/app/model/activityModel';
import {
    Result,
    Key
} from '@components/dashboard/Activity/interfaces/app/model/resolver/getToolbarValuesRsl';
import { Reducer } from '@components/lib/interfaces/index';

//api
import { PRODUCT } from '@interfaces/graphql/API';

//library
import { ParsedUrlQuery } from 'querystring';

/**
 * Function to return reducer
 *
 * @param {ParsedUrlQuery} query - The query.
 * @returns {Reducer<string, Result>} - The reducer function.
 */
const _reducer = (query: ParsedUrlQuery): Reducer<string, Result> => {
    /**
     * Reducer function to create toolbar form values.
     *
     * @param {Result} result - The result.
     * @param {string} current - The current key.
     * @returns {Result} - The result.
     */
    return (result: Result, current: string): Result => {
        if (!query[current]) {
            return result;
        }

        result[current as Key] = (
            Array.isArray(query[current]) ? query[current] : [query[current]]
        ) as string[];
        return result;
    };
};

/**
 * Resolver to make toolbar form initial values.
 *
 * @param {ParsedUrlQuery} query - The query.
 * @returns {Reducer<string, Result>} - The reducer function.
 */
const getToolbarValuesRsl = (query: ParsedUrlQuery): ToolbarFormValues => {
    const values = {
        owner: [],
        product: []
    };

    const result = Object.keys(values).reduce(_reducer(query), values);

    return {
        product: result.product as PRODUCT[],
        from: query.from as string,
        to: query.to as string
    };
};

export default getToolbarValuesRsl;
