//types
import {
    UseMainProps,
    UseMainReturn
} from '@components/dashboard/Activity/interfaces/app/controllers/controller/useMain';

//library
import { useEffect } from 'react';

/**
 * The Main controller
 *
 * @param {UseMainProps} props - The props.
 * @returns {UseMainReturn} - The main controller function.
 */
const useMain = ({
    activityModel,
    salesModel,
    query,
    state,
    dispatch,
    countryCode
}: UseMainProps): UseMainReturn => {
    const { loading } = state;

    /** Fetch stats for sales dashboard */
    const fetchItems = async () => {
        if (loading) {
            return;
        }

        //If query is empty, set it to default query
        if (Object.keys(query).length === 0) {
            activityModel.setUrl();
            return;
        }

        dispatch({ type: 'FETCH' });

        try {
            const response = await Promise.all([
                activityModel.getActivitiesAndDurations({ query }),
                salesModel.getDashboardSales(countryCode)
            ]);

            dispatch({
                type: 'LOAD_ITEMS',
                data: {
                    activity: response[0].activity,
                    duration: response[0].duration,
                    sales: response[1]
                }
            });
        } catch (error) {
            dispatch({ type: 'ERROR' });
        }
    };

    // Fetch the stats data whenever the query changes
    useEffect(() => {
        fetchItems();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return { fetchItems };
};

export default useMain;
