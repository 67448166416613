//type
import { ActivityProps } from '@components/dashboard/Activity/interfaces/DashboardActivities';

//library
import React, { ReactElement } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
import * as Formik from 'formik';
import * as Yup from 'yup';
import { Auth, API } from 'aws-amplify';

//resolver
import updateUrlRsl from '@components/lib/model/resolver/updateUrlRsl';
import formatCountryCodeRsl from '@components/lib/model/resolver/formatCountryCodeRsl';

//model
import salesModel from '@components/lib/model/salesModel/salesModel';

//code
import DashboardActivities from '@components/dashboard/Activity/DashboardActivities';
import Loader from '@components/lib/template/layout/Loader';
import DashboardRows from '@components/lib/template/layout/DashboardRows';
import DateInput from '@components/lib/module/input/date/Date';
import FilterInput from '@components/lib/module/input/filter/Filter';
import Menu from '@components/lib/module/layout/menu/Menu';
import ToolTip from '@components/lib/template/layout/ToolTip';
import SignOut from '@components/lib/module/layout/signOut/SignOut';
import FormatDate from '@components/lib/template/helper/FormatDate';

//list
import {
    listMyResidentialProduct,
    listResidentialProduct,
    residentialProduct
} from '@components/lib/list/product';

/**
 * The lead table.
 *
 * @returns {ReactElement} - The lead table page.
 */
const Home: React.FC = (): ReactElement => {
    const router = useRouter();
    const query = router.query;

    const props: ActivityProps = {
        lib: {
            model: {
                resolver: { updateUrlRsl, formatCountryCodeRsl },
                model: {
                    salesModel: salesModel({
                        lib: {
                            external: {
                                API
                            }
                        }
                    })
                }
            },
            template: {
                layout: {
                    Loader,
                    DashboardRows,
                    ToolTip
                },
                helper: { FormatDate }
            },
            module: {
                input: {
                    DateInput,
                    FilterInput
                },
                layout: {
                    Menu,
                    SignOut
                }
            },
            list: { listResidentialProduct, listMyResidentialProduct, residentialProduct },
            external: {
                query,
                router,
                Formik,
                Yup,
                Link,
                Image,
                Auth,
                API
            }
        }
    };

    return (
        <>
            <Head>
                <title>GetSolar AI: Sales Activity Dashboard</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <DashboardActivities {...props} />
        </>
    );
};

export default Home;
